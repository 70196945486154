@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');

/* 

font-family: 'Rubik', sans-serif;

*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::-webkit-input-placeholder {
  color: inherit;
}

::-moz-input-placeholder {
  color: inherit;
}

::-ms-input-placeholder {
  color: inherit;
}

.auto-container {
  position: static;
  max-width: 1440px;
  padding: 0px 15px;
  margin: 0 auto;

}

h1,
h2,
h3,
h4,
h5,
h6 {
  position: relative;
  font-weight: normal;
  margin: 0px;
  background: none;
  line-height: 1.2em;
  font-family: 'Rubik', sans-serif;
}

button:focus,
input:focus {
  outline: none !important;
  box-shadow: none !important;
}

input:-internal-autofill-selected {
  background: transparent !important;
  background-color: transparent !important;
}

input:-webkit-autofill {
  -webkit-transition-delay: 9999999s !important;
}

a {
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

a:hover,
a:focus,
a:visited {
  text-decoration: none;
  outline: none;
  text-decoration: none !important;
}

img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

.nfity-page {
  background: #F8F8F8;
  font-family: 'Rubik', sans-serif;
  color: #000000;
  min-height: 100vh;
}

.nfity-page.dark {
  background: #262626;
  font-family: 'Rubik', sans-serif;
  color: #fff;
}

.btn-style-one {
  position: relative;
  background-color: #2D85FF;
  border: 1px solid #2D85FF;
  color: #fff;
  font-size: 16px;
  padding: 10px 16px;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
}

.btn-style-one:hover {
  background-color: transparent;
  border: 1px solid #2D85FF;
  color: #2D85FF;
}

.btn-style-two {
  position: relative;
  background-color: transparent;
  border: 1px solid #707070;
  color: #707070;
  font-size: 16px;
  padding: 10px 16px;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
}

.btn-style-two:hover {
  background-color: #707070;
  border: 1px solid #707070;
  color: #fff;
}

.nfity-nav.dark .btn-style-two {
  border: 1px solid #9F9F9F;
  color: #fff;
}

.nfity-nav.dark .btn-style-two:hover {
  background-color: #9F9F9F;
  border: 1px solid #9F9F9F;
  color: #fff;
}

/* ========== Custom Scroll Styles =========== */

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cdcdcd;
  border-radius: 7px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #cdcdcd;
  border-radius: 7px;
}

/* width */
.nfity-page.dark .chat-sec .left-content-area .chatbox-friends::-webkit-scrollbar,
.nfity-page.dark .chat-sec .right-content-area .chat-box .chatbox-chat-inner .chatbox-body::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.nfity-page.dark .chat-sec .left-content-area .chatbox-friends::-webkit-scrollbar-track,
.nfity-page.dark .chat-sec .right-content-area .chat-box .chatbox-chat-inner .chatbox-body::-webkit-scrollbar-track {
  background: #000;
}

/* Handle */
.nfity-page.dark .chat-sec .left-content-area .chatbox-friends::-webkit-scrollbar-thumb,
.nfity-page.dark .chat-sec .right-content-area .chat-box .chatbox-chat-inner .chatbox-body::-webkit-scrollbar-thumb {
  background: #cdcdcd;
  border-radius: 7px;
}

/* Handle on hover */
.nfity-page.dark .chat-sec .left-content-area .chatbox-friends::-webkit-scrollbar-thumb:hover,
.nfity-page.dark .chat-sec .right-content-area .chat-box .chatbox-chat-inner .chatbox-body::-webkit-scrollbar-thumb:hover {
  background: #cdcdcd;
  border-radius: 7px;
}

/* ========== Main Modal Styles =========== */

.main-modal {
  max-width: 655px !important;
  top: 5%;
}

.main-modal.comment-modal {
  max-width: 1105px !important;
  top: 2%;
  zoom: 0.77;
}

.main-modal.share-post-modal {
  zoom: 0.87;
}

.modal-dialog.main-modal.profile-done-modal {
  max-width: 606px !important;
}

.main-modal .modal-header,
.modal-footer {
  border: none !important;
}

.main-modal .modal-content {
  text-align: center;
  position: relative;
  padding: 43px 20px;
  background-color: #fff;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  border-radius: 16px;
}

.main-modal.dark .modal-content {
  background-color: #000;
}

.main-modal .modal-content .modal-header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9;
}

.main-modal .modal-content .modal-header .close {
  color: #fff !important;
}

.main-modal .modal-content .modal-header .modal-logo {
  display: flex;
  justify-content: center;
}

.main-modal .modal-content .modal-header .modal-logo img {
  width: 35%;
  padding: 25px;
}

.main-modal .modal-content .modal-body {
  padding: 0px;
}

.makeanoffer-modal .modal-content .modal-header .close {
  color: #000 !important;
}

.nft-sell-modal .modal-content .modal-header .close {
  color: #000 !important;
}

.modal-dialog.main-modal.profile-done-modal .modal-body .content-area .validatorForm .group-input p {
  position: absolute;
  bottom: -53px;
  left: -13px;
  font-size: 12px;
}

.nft-sell-modal {
  max-width: 525px !important;
  top: 1%;
}

.share-post-modals {
  max-width: 901px !important;
  top: 5%;
}

@media(max-width: 1600px) {
  .auto-container {
    max-width: 1640px;
  }

  .share-post-modals {
    max-width: 746px !important;
  }
}

@media(max-width: 1450px) {
  .auto-container {
    max-width: 1540px;
    padding: 0 30px;
  }

  .share-post-modals {
    max-width: 595px !important;
  }

  .nfity-page {
    min-height: auto;
  }
}

@media(max-width: 1280px) {
  .auto-container {
    padding: 0 50px;
  }
}

@media(max-width: 1199px) {
  .auto-container {
    padding: 0 75px;
  }
}

@media(max-width: 767px) {
  .auto-container {
    padding: 0 30px;
  }

  .modal-dialog.main-modal.profile-done-modal,
  .share-post-modals {
    max-width: 90% !important;
    margin: 0 auto !important;
  }

  .main-modal.comment-modal {
    max-width: 100% !important;
    margin: 0 20px;
  }

  .comment-modal .modal-content .content-area .comment-block {
    background-image: none;
  }

  .comment-modal .modal-content .content-area .comment-block .comment-box .comment-area {
    padding: 12px 15px;
  }
}

@media(max-width: 565px) {
  .nft-sell-modal {
    max-width: 100% !important;
    top: 1%;
  }
}

@media(max-width: 520px) {
  .modal-dialog.main-modal.image-modal .modal-body .content-area .input-file .content-btn {
    padding: 9px 13px;
    top: 13px;
  }

  .modal-dialog.main-modal.image-modal .modal-body .content-area .input-file .content-btn .left-area .text-box h5 {
    font-size: 12px;
  }

  .modal-dialog.main-modal.image-modal .modal-body .content-area .input-file .content-btn .left-area .text-box p {
    font-size: 10px;
  }

  .modal-dialog.main-modal.image-modal .modal-body .content-area .input-file {
    height: 84px;
  }
}

@media(max-width: 480px) {
  .comment-modal .modal-content .content-area .comment-block .comment-box .comment-area .comment .content .img-box img {
    width: 40px;
    height: 40px;
  }

  .comment-modal .modal-content .content-area .comment-block .comment-box .comment-area .comment .content .text-box {
    padding-left: 50px;
  }

  .comment-modal .modal-content .content-area .comment-block .comment-box .comment-area .reply.comment {
    margin-left: 13px;
  }

  .comment-modal .modal-content .content-area .comment-block .comment-box .comment-area .comment .content .text-box p {
    font-size: 12px;
    padding-right: 0;
    margin-bottom: 7px;
  }

  .comment-modal .modal-content .content-area .comment-block .send-box {
    margin: 10px;
    padding: 10px;
    display: block;
    width: 95%;
    left: 6px;
    position: relative;
  }

  .comment-modal .modal-content .content-area .comment-block .send-box .img-upload-btn {
    position: absolute;
    margin: 0 0;
    z-index: 9;
    width: 40px;
    right: 78px;
    top: 18px;
  }

  .comment-modal .modal-content .content-area .comment-block .send-box .img-upload-btn .upload-btn {
    top: 0;
    right: 10px;
  }

  .comment-modal .modal-content .content-area .comment-block .send-box .group-form {
    display: block;
  }

  .comment-modal .modal-content .content-area .comment-block .send-box .group-form input.form-control {

    width: 100%;
  }

  .comment-modal .modal-content .content-area .comment-block .send-box .group-form .attached {
    right: 44px;
    top: 7px;
  }

  .comment-modal .modal-content .content-area .comment-block .send-box .group-form .smiles {
    right: 12px;
    top: 9px;
  }

  .comment-modal .modal-content .content-area .comment-block .send-box .group-form .send-button {
    padding: 7px;
    width: 100%;
    margin-top: 12px;
    margin-left: 0;
  }
}

@media(max-width: 430px) {
  .modal-dialog.main-modal.image-modal .modal-body .content-area .input-file .content-btn {
    display: block;
  }

  .modal-dialog.main-modal.image-modal .modal-body .content-area .input-file .content-btn .left-area,
  .modal-dialog.main-modal.image-modal .modal-body .content-area .input-file .content-btn .right-area {
    width: 100%;
  }

  .modal-dialog.main-modal.image-modal .modal-body .content-area .input-file .content-btn .right-area .btn-style-two {
    width: 100%;
    margin-top: 14px;
  }

  .modal-dialog.main-modal.image-modal .modal-body .content-area .input-file {
    height: 116px;
  }
}

@media(max-width: 400px) {
  .auto-container {
    padding: 0 15px;
  }

  .modal-dialog.main-modal.image-modal .modal-body .content-area .input-file .content-btn {
    padding: 9px 10px;
    top: 4px;
  }
}